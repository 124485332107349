<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container class="pb-0 mb-0">
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="pt-0"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Empresa -->
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    <v-autocomplete
                      v-model="empresaSelected"
                      :items="empresasItems"
                      item-text="value"
                      item-value="id"
                      return-object
                      @change="
                        setComprobantesByEntFac(
                          empresaSelected != null ? empresaSelected.id : -1
                        )
                      "
                      outlined
                      dense
                      clearable
                      label="Entidad facturante"
                    ></v-autocomplete>
                  </v-col>
                  <!-- tipo de fecha -->
                  <v-col cols="3" sm="3" md="3" class="py-0">
                    <v-autocomplete
                      v-model="tipoFechaSelected"
                      :items="itemsTiposFecha"
                      return-object
                      label="Tipo de fecha"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- fecha desde -->
                  <v-col cols="3" sm="3" md="3" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaDesde =
                              helpers.parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(
                                helpers.parseDateToIso(fechaDesdeSelected)
                              ) <=
                                new Date(
                                  helpers.parseDateToIso(fechaHastaSelected)
                                ) || 'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="
                          fechaDesdeSelected = helpers.formatDate(fechaDesde)
                        "
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- fecha hasta -->
                  <v-col cols="3" sm="3" md="3" class="py-0">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaHasta =
                              helpers.parseDateToIso(fechaHastaSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(
                                helpers.parseDateToIso(fechaDesdeSelected)
                              ) <=
                                new Date(
                                  helpers.parseDateToIso(fechaHastaSelected)
                                ) || 'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="
                          fechaHastaSelected = helpers.formatDate(fechaHasta)
                        "
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      clearable
                      dense
                      label="Código proveedor"
                      v-model.trim="provCod"
                      :rules="[rules.maxLength(provCod, 10)]"
                      @change="setProveedor()"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip top max-width="50%">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="toggleModalBusquedaProv"
                            >
                              {{ searchIcon }}
                            </v-icon>
                          </template>
                          <span
                            >Buscar proveedor por nombre, CUIT o código</span
                          >
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <!-- DATOS PROVEEDOR -->
                  <v-col cols="12" md="2" class="py-0">
                    <v-tooltip top :disabled="provNom == null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          filled
                          disabled
                          readonly
                          label="Proveedor"
                          hide-details=""
                          :loading="proveedoresLoading"
                          v-model.trim="provNom"
                          autocomplete="off"
                        >
                        </v-text-field>
                      </template>
                      <span>{{ provNom ? provNom : "" }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    <v-autocomplete
                      v-model="tipoComprobanteSelected"
                      :items="tipoComprobantesItems"
                      item-text="value"
                      item-value="id"
                      return-object
                      label="Tipo de comprobante"
                      outlined
                      multiple
                      dense
                      clearable
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="selectAllItems">
                          <v-list-item-action>
                            <v-icon :color="isSelectedAll ? 'primary' : ''">
                              {{
                                isSelectedAll
                                  ? "mdi-checkbox-marked"
                                  : "mdi-checkbox-blank-outline"
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Todos </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="selectByDescription('CAN')">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                isSelectedByDescription('CAN') || isSelectedAll
                                  ? 'primary'
                                  : ''
                              "
                            >
                              {{
                                isSelectedByDescription("CAN") || isSelectedAll
                                  ? "mdi-checkbox-marked"
                                  : "mdi-checkbox-blank-outline"
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Solo cancelatorios
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="selectByDescription('DE')">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                isSelectedByDescription('DE') || isSelectedAll
                                  ? 'primary'
                                  : ''
                              "
                            >
                              {{
                                isSelectedByDescription("DE") || isSelectedAll
                                  ? "mdi-checkbox-marked"
                                  : "mdi-checkbox-blank-outline"
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Solo de deudas
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="selectByDescription('OP')">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                isSelectedByDescription('OP') || isSelectedAll
                                  ? 'primary'
                                  : ''
                              "
                            >
                              {{
                                isSelectedByDescription("OP") || isSelectedAll
                                  ? "mdi-checkbox-marked"
                                  : "mdi-checkbox-blank-outline"
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Solo órdenes de pago
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.value }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ tipoComprobanteSelected.length - 1 }} otros)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Letra y numero de comprobante -->
                  <v-col cols="12" md="1" class="py-0 p-0">
                    <v-text-field
                      type="text"
                      class="center-input"
                      v-model="letraComprobante"
                      outlined
                      dense
                      persistent-hint
                      v-mask="'A'"
                      hint="Letra"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0 p-0" cols="12" md="1">
                    <v-text-field
                      type="text"
                      v-model="sucursalCompr"
                      outlined
                      dense
                      class="center-input"
                      v-mask="'#####'"
                      persistent-hint
                      hint="Sucursal"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0 pr-n2 pl-0" cols="12" md="2">
                    <v-text-field
                      type="text"
                      v-model="nroComprobante"
                      outlined
                      dense
                      v-mask="'########'"
                      persistent-hint
                      hint="Número comprobante"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Tipos proveedor -->
                  <v-col cols="12" sm="4" md="4" class="py-0">
                    <v-autocomplete
                      v-model="tipoProveedorSelected"
                      :items="tiposProveedoresItems"
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Tipo proveedor"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Switch Anulados -->
                  <v-col cols="2" class="py-0">
                    <v-switch
                      class="mt-0"
                      v-model="anuladosSwitch"
                      label="Anulados"
                      :disabled="conSaldoSwitch"
                    ></v-switch>
                  </v-col>
                  <!-- Switch Con saldo -->
                  <v-col cols="2" class="py-0 pr-0">
                    <v-switch
                      class="mt-0"
                      v-model="conSaldoSwitch"
                      label="Con saldo al:"
                      :disabled="anuladosSwitch"
                    ></v-switch>
                  </v-col>
                  <!-- Fecha con saldo al: (esta fecha va a ser siempre la misma de "fechaHasta" y va siempre disabled)-->
                  <v-col
                    v-if="conSaldoSwitch"
                    cols="12"
                    sm="3"
                    md="3"
                    class="py-0 pl-7 pr-0"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menuFechaSaldo"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="formato DD/MM/AAAA"
                          @keydown.tab="menuFechaSaldo = false"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            [
                              (fechaDesde =
                                helpers.parseDateToIso(fechaHastaSelected)),
                            ]
                          "
                          @change="
                            [
                              (fechaDesde =
                                helpers.parseDateToIso(fechaHastaSelected)),
                            ]
                          "
                          clearable
                          dense
                          disabled
                          outlined
                          autocomplete="off"
                          :rules="
                            conSaldoSwitch
                              ? rules.required.concat(rules.validDate)
                              : []
                          "
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        scrollable
                        @change="
                          [
                            (fechaHastaSelected =
                              helpers.formatDate(fechaDesde)),
                          ]
                        "
                        @input="menuFechaSaldo = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    v-if="conSaldoSwitch"
                    cols="1"
                    md="1"
                    sm="1"
                    class="py-0 pl-8"
                  >
                    <v-tooltip right max-width="10rem">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          size="1.4rem"
                          class="pt-2"
                          v-on="on"
                          v-bind="attrs"
                          color="primary"
                        >
                          {{ infoIcon }}</v-icon
                        >
                      </template>
                      <span>Se aplicará solo a comprobantes de deuda</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row class="pb-0">
                  <v-col
                    v-if="canSeeMultimoneda"
                    cols="12"
                    sm="3"
                    md="3"
                    class="py-0"
                  >
                    <v-autocomplete
                      v-model="monedaSelected"
                      :items="monedasItems"
                      item-text="value"
                      item-value="id"
                      clearable
                      @change="changeMoneda"
                      return-object
                      :rules="canSeeMultimoneda ? rules.required : []"
                      label="Moneda de consulta (*)"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    v-if="canSeeMultimoneda && verFechaCotizaYSwitch"
                    cols="12"
                    sm="3"
                    md="3"
                    class="py-0"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menuFecCotiza"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaCotizaTextField"
                          label="Fecha cotización"
                          :append-icon="calendarIcon"
                          :disabled="cotizarFechaCompSwitch"
                          v-mask="'##/##/####'"
                          hint="formato DD/MM/AAAA"
                          @keydown.tab="menuFecCotiza = false"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            [
                              (fechaCotiza =
                                helpers.parseDateToIso(fechaCotizaTextField)),
                            ]
                          "
                          @change="
                            [
                              (fechaCotiza =
                                helpers.parseDateToIso(fechaCotizaTextField)),
                            ]
                          "
                          clearable
                          dense
                          outlined
                          autocomplete="off"
                          :rules="
                            canSeeMultimoneda && !cotizarFechaCompSwitch
                              ? rules.required.concat(rules.validDate)
                              : []
                          "
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>

                      <v-date-picker
                        v-model="fechaCotiza"
                        no-title
                        scrollable
                        @change="
                          [
                            (fechaCotizaTextField =
                              helpers.formatDate(fechaCotiza)),
                          ]
                        "
                        @input="menuFecCotiza = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    v-if="canSeeMultimoneda && verFechaCotizaYSwitch"
                    class="py-0 pt-2"
                  >
                    <v-switch
                      class="mt-n2"
                      v-model="cotizarFechaCompSwitch"
                      @change="setFechaCotizaBySwitch"
                      label="Cotizar a la fecha de comprobante"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-0">
                  <v-col cols="4">
                    <v-switch
                      class="mt-n3"
                      v-model="sinCompFiscal"
                      label="Sin comprobante fiscal asociado"
                    ></v-switch>
                  </v-col>
                  <v-col cols="1">
                    <v-tooltip right max-width="10rem">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          size="1.4rem"
                          class="mt-n4 pl-0"
                          v-on="on"
                          v-bind="attrs"
                          color="primary"
                        >
                          {{ infoIcon }}</v-icon
                        >
                      </template>
                      <span
                        >Se recomienda filtrar también por tipo de
                        comprobante</span
                      >
                    </v-tooltip>
                  </v-col>
                  <v-col cols="7" align="end" class="text-right pt-0 pb-0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
            <p
              :class="
                showFilters
                  ? 'invalidConfigText mt-n6 mb-1'
                  : 'invalidConfigText mt-n1 mb-0 ml-2'
              "
              v-if="!this.haveCotiza && this.canSeeMultimoneda"
            >
              Sin cotización
            </p>
          </v-container>
        </v-card>
        <v-card>
          <v-row>
            <v-col col="9" class="pt-0 pb-0" align="start" align-self="center">
              <v-btn-toggle dense v-model="text" color="primary" group>
                <v-btn value="DETALLE COMPROBANTES" @click="switchTab(1)">
                  Detalle comprobantes
                </v-btn>
                <v-btn @click="switchTab(2)"> Totales por proveedor </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="3" class="mr-n8">
              <v-btn
                v-if="canAutorizar"
                color="primary"
                small
                @click="goTo('AutorizacionesProveedor')"
                class="to-right"
              >
                Autorización comprobantes a pagar
              </v-btn>
            </v-col>
            <v-col cols="3" align="end" class="mr-4">
              <v-btn
                v-if="canImportar"
                color="primary"
                small
                @click="goTo('ImportacionFacturas')"
                class="to-right"
              >
                Importación de Facturas
              </v-btn>
            </v-col>
          </v-row>
          <!-- TABLA DE DETALLES -->
          <section v-if="showDetallesTable">
            <v-data-table
              :headers="headersDetallesTable"
              :items="itemsDetalles"
              height="100%"
              fixed-header
              show-expand
              :expanded.sync="expanded"
              class="elevation-1"
              :loading="isLoadingTable"
              item-key="mProvId"
            >
              <template
                v-if="itemsDetalles.length > 0"
                v-slot:[`body.append`]="{ headers }"
              >
                <tr>
                  <td v-for="(header, i) in headers" :key="i">
                    <div v-if="header.value == 'fechaContable'">Total</div>
                    <div
                      :style="{ 'text-align': 'right' }"
                      v-if="header.value == 'importeTotalToShow'"
                    >
                      <strong> ${{ sumatoriaImportesDetalle }}</strong>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="goToVerComprobante(item)"
                    >
                      {{ seeIcon }}
                    </v-icon>
                  </template>
                  <span>Ver comprobante</span>
                </v-tooltip>
                <v-tooltip left v-if="canDelete">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="goToDeleteComprobante(item)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Eliminar comprobante</span>
                </v-tooltip>
              </template>
              <template
                v-slot:[`item.data-table-expand`]="{ item, isExpanded }"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="handleExpansion(item, isExpanded)"
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      >{{
                        isExpanded ? chevronUpIcon : chevronDownIcon
                      }}</v-icon
                    >
                  </template>
                  <span>Observaciones</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-row>
                    <v-col cols="12" md="12">
                      <strong>Observaciones:</strong>
                      {{ item.observaciones }}
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </section>
          <!-- TABLA DE TOTALES -->
          <section v-if="showTotalesTable">
            <v-data-table
              :headers="headersTotalesTable"
              :items="itemsTotales"
              height="100%"
              fixed-header
              class="elevation-1"
              :loading="isLoadingTable"
              item-key="mProvId"
            >
              <template
                v-if="itemsTotales.length > 0"
                v-slot:[`body.append`]="{ headers }"
              >
                <tr class="totales">
                  <td v-for="(header, i) in headers" :key="i">
                    <div v-if="header.value == 'proveedorCodNom'">Total</div>
                    <div
                      class="to-right"
                      v-if="header.value == 'importeTotalToShow'"
                    >
                      <strong> ${{ sumatoriaImportesTotales }}</strong>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </section>
        </v-card>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="6" md="7" align="right" class="py-3">
            <v-tooltip top max-width="40%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="fontsize"
                  v-bind="attrs"
                  outlined
                  :disabled="itemsDetalles.length === 0"
                  @click="exportExcelModelo"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-2 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <!-- dialogs para eliminar -->
    <v-dialog
      v-if="openModalDeleteOp"
      v-model="openModalDeleteOp"
      max-width="60%"
      @keydown.esc="openModalDeleteOp = false"
      persistent
    >
      <VerOrdenDePago
        :isDelete="true"
        @closeModalDelete="(openModalDeleteOp = false), applyFilters()"
        :paramsToDeleteOp="paramsToDeleteOp"
      ></VerOrdenDePago>
    </v-dialog>
    <v-dialog
      v-if="openModalDelete"
      v-model="openModalDelete"
      max-width="65%"
      @keydown.esc="openModalDelete = false"
      persistent
    >
      <DetalleComprobanteProv
        :isDelete="true"
        @closeModalDelete="(openModalDelete = false), applyFilters()"
        :paramsToDelete="paramsToDelete"
      ></DetalleComprobanteProv>
    </v-dialog>
    <!-- MODAL BUSCAR PROVEEDOR -->
    <v-dialog
      v-model="buscarProveedorModal"
      v-if="buscarProveedorModal"
      @keydown.esc="toggleModalBusquedaProv"
      max-width="70%"
      persistent
    >
      <BuscarProveedor
        @toggleModalBusquedaProv="toggleModalBusquedaProv"
        @findProveedor="findProveedor"
      ></BuscarProveedor>
    </v-dialog>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import PageHeader from "@/components/ui/PageHeader";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mask } from "vue-the-mask";
import helpersExcel from "@/utils/helpers/importExportExcel";
import VerOrdenDePago from "@/components/modules/proveedores/ordenesPago/VerOrdenDePago.vue";
import DetalleComprobanteProv from "@/components/modules/proveedores/cuentaCorriente/DetalleComprobanteProv.vue";
import helpers from "@/utils/helpers.js";
import BuscarProveedor from "@/components/modules/proveedores/BuscarProveedor.vue";

export default {
  name: "ComprobantesProveedor",
  components: {
    Ayuda,
    FiltersSelected,
    PageHeader,
    VerOrdenDePago,
    BuscarProveedor,
    DetalleComprobanteProv,
  },
  directives: { mask },
  data: (vm) => ({
    rules: rules,
    helpers: helpers,
    title: enums.titles.COMPROBANTES_PROVEEDOR,
    infoIcon: enums.icons.SNB_INFO,
    seeIcon: enums.icons.SEE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    chevronUpIcon: enums.icons.CHEVRON_UP,
    chevronDownIcon: enums.icons.CHEVRON_DOWN,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    expanded: [],
    allowedActions: null,
    optionCode: enums.webSiteOptions.COMPROBANTES_PROVEEDOR,
    showHelp: false,
    isFormValid: false,
    showFilters: true,
    showExpand: false,
    //los totales que van debajo de la tabla
    sumatoriaImportesDetalle: null,
    sumatoriaImportesTotales: null,
    //filters
    sucursalCompr: "",
    tipoFechaSelected: { id: 1, value: "Fecha comprobante" },
    itemsTiposFecha: [
      { id: 0, value: "Fecha contable" },
      { id: 1, value: "Fecha de comprobante" },
      { id: 2, value: "Fecha de vencimiento" },
    ],
    fechaDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 10)
      .toISOString()
      .substr(0, 10),
    fechaDesdeSelected: helpers.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 10)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: null,
    fechaHastaSelected: helpers.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaHasta: new Date().toISOString().substr(0, 10),
    menu2: null,
    menuFechaSaldo: null,
    tipoComprobanteSelected: [],
    tipoComprobantesItems: [],
    letraComprobante: "",
    nroComprobante: "",
    empresaSelected: null,
    text: "DETALLE COMPROBANTES",
    empresasItems: [],
    tipoProveedorSelected: null,
    tiposProveedoresItems: [],
    conSaldoSwitch: false,
    anuladosSwitch: false,
    proveedorSelected: null,
    proveedorAutocompleteInput: null,
    proveedoresLoading: false,
    proveedorItems: [],
    monedasItems: [],
    monedaDeProv: null,
    monedaSelected: null,
    cotizarFechaCompSwitch: false,
    buscarProveedorModal: false,
    sinCompFiscal: false,
    fechaCotizaTextField: helpers.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaCotiza: new Date().toISOString().substr(0, 10),
    menuFecCotiza: false,
    //tables
    showDetallesTable: true,
    showTotalesTable: false,
    itemsTotales: [],
    openModalDeleteOp: false,
    openModalDelete: false,
    paramsToDelete: null,
    paramsToDeleteOp: null,
    isLoadingTable: false,
    headersTotalesTable: [
      {
        text: "Proveedor",
        sortable: false,
        value: "proveedorCodNom",
        align: "start",
      },
      {
        text: "Importe total",
        sortable: false,
        value: "importeTotalToShow",
        align: "right",
      },
    ],
    itemsDetalles: [],
    filtersApplied: [],
    headersDetallesTable: [
      {
        text: "Proveedor",
        sortable: false,
        value: "proveedorCodNom",
      },
      {
        text: "Número",
        sortable: false,
        value: "comprobanteNro",
      },
      {
        text: "Fecha de comprobante",
        sortable: false,
        value: "fechaComprobante",
      },
      {
        text: "Fecha contable",
        sortable: false,
        value: "fechaContable",
      },
      {
        text: "Importe",
        sortable: false,
        align: "right",
        value: "importeTotalToShow",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end",
      },
      { text: "", align: "end", value: "data-table-expand" },
    ],
    canSeeMultimoneda: false,
    saveCamposFilters: {},
    haveCotiza: true,
    canDelete: false,
    canImportar: false,
    canAutorizar: false,
    verFechaCotizaYSwitch: false,
    monedaDefectoSistema: null,
    provCod: null,
    provNom: null,
    provId: null,
  }),
  computed: {
    isSelectedAll() {
      return (
        this.tipoComprobanteSelected.length ===
        this.tipoComprobantesItems.length
      );
    },
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  },
  beforeRouteLeave(to, from, next) {
    // Valido que si no es el componente VerOrdenDePago, borre los filtros
    // sino se quedaban grabados siempre por más que vayas a cualquier pantalla
    if (to.path.includes("/proveedores/ver-orden-pago")) next();
    else if (to.path.includes("/proveedores/ver-comprobante-proveedores"))
      next();
    else {
      this.$store.state.filtersComprobantesProveedor = null;
      next();
    }
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setPermisos();
    this.setSelects();
    if (this.$store.state.filtersComprobantesProveedor != undefined)
      this.setFilters(this.$store.state.filtersComprobantesProveedor);
  },
  methods: {
    ...mapActions({
      getAllTipoComprobantes: "proveedores/getAllTipoComprobantes",
      fetchProveedoresByQuery: "proveedores/fetchProveedoresByQuery",
      getMonedaDeProveedor: "proveedores/getMonedaDeProveedor",
      fetchTiposProveedores: "proveedores/fetchTiposProveedores",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getComprobantesProveedorByFilters:
        "proveedores/getComprobantesProveedorByFilters",
      getProveedoresByCod: "afiliaciones/getProveedoresByCod",
      setAlert: "user/setAlert",
    }),
    goTo(route) {
      this.$router.push({
        name: route,
      });
    },
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.IMPORT_FACURA:
            this.canImportar = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_COMPROBANTE_COMP_PROV:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .AUTORIZACION_FACTURAS_PROVEEDOR:
            this.canAutorizar = true;
            break;
          default:
            break;
        }
      });
    },
    async setFilters(filters) {
      this.sinCompFiscal = filters.sinCompFiscal;
      this.monedaSelected = filters.moneda;
      this.empresaSelected = filters.empresa;
      this.tipoFechaSelected = filters.tipoFechaSelected;
      this.fechaDesde = filters.fechaDesde;
      this.fechaDesdeSelected = filters.fechaDesdeSelected;
      this.tipoComprobanteSelected = filters.tipoComprobanteId;
      this.cotizarFechaCompSwitch = filters.cotizaFechaComprobante;
      this.fechaHasta = filters.fechaHasta;
      this.fechaHastaSelected = filters.fechaHastaSelected;
      this.conSaldoSwitch = filters.conSaldoSwitch;
      this.letraComprobante = filters.letra;
      this.sucursalCompr = filters.sucursal;
      this.nroComprobante = filters.nroComp;
      this.tipoProveedorSelected = filters.tipoProveedor;
      if (filters.proveedor != null) {
        this.provCod = parseInt(filters.proveedor.value2);
        this.provNom = filters.proveedor.value1;
        this.provId = filters.proveedor.provId;
        this.proveedorSelected = filters.proveedor;
        this.monedaDeProv = filters.monedaDeProv;
      }
      this.anuladosSwitch = filters.anulados;
      this.fechaHasta = filters.fechaSaldo;
      this.fechaSaldoTextField = filters.fechaSaldoTextfield;
      if (
        this.verFechaCotizaYSwitch &&
        this.monedaSelected == this.monedaDefectoSistema
      ) {
        this.fechaCotiza = filters.fechaCotiza;
        this.fechaCotizaTextField = filters.fechaCotizaTextfield;
      }
      this.canSeeMultimoneda = filters.canSeeMultimoneda;
      this.verFechaCotizaYSwitch = filters.verFechaCotizaYSwitch;
      this.applyFilters();
    },
    async setSelects() {
      this.setComprobantesByEntFac(-1);
      const tiposProveedores = await this.fetchTiposProveedores();
      this.tiposProveedoresItems = tiposProveedores;
      const entidadesFacturantes = await this.getEntidadesFacturantes();
      this.empresasItems = entidadesFacturantes;
      const responseParametrosGrales = await this.getParamGralMultimoneda();
      if (responseParametrosGrales[0].multimonedaParams) {
        this.canSeeMultimoneda = true;
        const monedas = await this.getMonedasTaxonomy("habListado");
        this.monedasItems = monedas;
        this.monedaDefectoSistema = monedas.find((x) => x.bool == true);
        if (this.$store.state.filtersComprobantesProveedor == undefined) {
          this.monedaSelected = this.monedasItems.find((x) => x.bool);
          this.verFechaCotizaYSwitch = false;
        }
      }
    },
    updateBanderaFechaCotiza() {
      if (
        this.monedaSelected === this.monedasItems.find((x) => x.bool) ||
        (this.monedaDeProv && this.monedaDeProv.id === this.monedaSelected.id)
      )
        this.verFechaCotizaYSwitch = false;
      else this.verFechaCotizaYSwitch = true;
    },
    async changeMoneda() {
      this.updateBanderaFechaCotiza();
      if (this.verFechaCotizaYSwitch) this.setFechaCotizaBySwitch();
      else {
        this.cotizarFechaCompSwitch = false;
        this.verFechaCotizaYSwitch = false;
        this.fechaCotizaTextField = null;
        this.fechaCotiza = null;
      }
    },
    async setComprobantesByEntFac(entFacId) {
      const tipoComprobante = await this.getAllTipoComprobantes(entFacId);
      this.tipoComprobantesItems = tipoComprobante;
    },
    async setMonedaDelProveedor() {
      if (this.provNom) {
        const monedaDelProv = await this.getMonedaDeProveedor(this.provId);
        this.monedaDeProv = monedaDelProv;
        this.monedaSelected = this.monedasItems.find(
          (x) => x?.id == monedaDelProv?.id
        );
        this.updateBanderaFechaCotiza();
      } else this.monedaDeProv = null;
    },
    setFechaCotizaBySwitch() {
      if (this.cotizarFechaCompSwitch) {
        this.fechaCotizaTextField = null;
        this.fechaCotiza = null;
      } else {
        let now = new Date();
        let año = now.getFullYear();
        let mes = now.getMonth() + 1;
        let dia = now.getDate();
        dia < 10 ? (dia = `0${dia}`) : dia;
        mes < 10 ? (mes = `0${mes}`) : mes;
        this.fechaCotiza = `${año}-${mes}-${dia}`;
        this.fechaCotizaTextField = this.helpers.formatDate(this.fechaCotiza);
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.empresaSelected) {
        this.filtersApplied.splice(4, 1, {
          key: "empresaSelected.value",
          label: "Entidad facturante",
          model: this.empresaSelected.value,
        });
      }
      if (this.tipoFechaSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "tipoFechaSelected",
          label: "Tipo de fecha",
          model: this.tipoFechaSelected.value,
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "fechaDesdeSelected",
          label: "Fecha desde",
          model: this.fechaDesdeSelected,
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "fechaHastaSelected",
          label: "Fecha hasta",
          model: this.fechaHastaSelected,
        });
      }
      if (this.tipoComprobanteSelected) {
        this.filtersApplied.splice(5, 1, {
          key: "tipoComprobanteSelected",
          label: "Tipo de comprobante",
          model: this.tipoComprobanteSelected,
        });
      }
      if (this.letraComprobante || this.sucursalCompr || this.nroComprobante) {
        this.filtersApplied.splice(6, 1, {
          key: "nroComprobante",
          label: "Número comprobante",
          model: `${this.letraComprobante} - ${this.sucursalCompr} - ${this.nroComprobante}`,
        });
      }
      if (this.tipoProveedorSelected) {
        this.filtersApplied.splice(7, 1, {
          key: "tipoProveedorSelected",
          label: "Tipo proveedor",
          model: this.tipoProveedorSelected.value,
        });
      }
      if (this.proveedorSelected || this.provNom) {
        this.filtersApplied.splice(8, 1, {
          key: "proveedorSelected.value1",
          label: "Proveedor",
          model: `${this.proveedorSelected.value2 ?? this.provNom} - ${
            this.proveedorSelected.value1
          }`,
        });
      }
      if (this.anuladosSwitch) {
        this.filtersApplied.splice(9, 1, {
          key: "anuladosSwitch",
          label: "Anulados",
          model: this.anuladosSwitch ? "SI" : "NO",
        });
      }
      if (this.conSaldoSwitch) {
        this.filtersApplied.splice(10, 1, {
          key: "conSaldoSwitch",
          label: `Con saldo al ${this.fechaHastaSelected}`,
          model: this.conSaldoSwitch ? "SI" : "",
        });
      }
      if (this.monedaSelected) {
        this.filtersApplied.splice(11, 1, {
          key: "monedaSelected",
          label: "Moneda",
          model: this.monedaSelected.value,
        });
      }
      if (
        this.fechaCotizaTextField &&
        this.canSeeMultimoneda &&
        this.verFechaCotizaYSwitch &&
        !this.cotizarFechaCompSwitch
      ) {
        this.filtersApplied.splice(12, 1, {
          key: "fechaCotizaTextField",
          label: "Fecha cotización",
          model: this.fechaCotizaTextField,
        });
      }
      if (
        this.cotizarFechaCompSwitch &&
        this.canSeeMultimoneda &&
        this.verFechaCotizaYSwitch
      ) {
        this.filtersApplied.splice(13, 1, {
          key: "cotizarFechaCompSwitch",
          label: "Cotizar a la fecha del comprobante",
          model: this.cotizarFechaCompSwitch ? "SI" : "NO",
        });
      }
      if (this.sinCompFiscal) {
        this.filtersApplied.splice(14, 1, {
          key: "sinCompFiscal",
          label: "Sin comprobante fiscal asociado",
          model: this.sinCompFiscal ? "SI" : "NO",
        });
      }
    },
    async applyFilters() {
      this.customizeFiltersApplied();
      this.haveCotiza = true;
      this.isLoadingTable = true;
      this.showFilters = false;
      this.sumatoriaImportesDetalle = null;
      this.sumatoriaImportesTotales = null;
      this.saveCamposFilters = {
        empresa: this.empresaSelected,
        tipoFechaSelected: this.tipoFechaSelected,
        fechaDesde: this.fechaDesde,
        fechaDesdeSelected: this.fechaDesdeSelected,
        fechaHasta: this.fechaHasta,
        fechaHastaSelected: this.fechaHastaSelected,
        letra: this.letraComprobante,
        sucursal: this.sucursalCompr,
        nroComp: this.nroComprobante,
        tipoProveedor: this.tipoProveedorSelected,
        proveedor: this.proveedorSelected,
        provId: this.provId,
        anulados: this.anuladosSwitch,
        fechaSaldo: this.fechaHasta,
        conSaldoSwitch: this.conSaldoSwitch,
        fechaSaldoTextfield: this.fechaSaldoTextField,
        moneda: this.monedaSelected,
        fechaCotiza: !this.verFechaCotizaYSwitch ? null : this.fechaCotiza,
        fechaCotizaTextfield: !this.verFechaCotizaYSwitch
          ? null
          : this.fechaCotizaTextField,
        cotizaFechaComprobante: this.cotizarFechaCompSwitch,
        tipoComprobanteId: this.tipoComprobanteSelected,
        sinCompFiscal: this.sinCompFiscal,
        verFechaCotizaYSwitch: this.verFechaCotizaYSwitch,
        canSeeMultimoneda: this.canSeeMultimoneda,
        monedaDeProv: this.monedaDeProv,
      };
      const filters = {
        tipoFecha: this.tipoFechaSelected.id,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        entFacId: this.empresaSelected?.id,
        tipoComprobanteId: this.tipoComprobanteSelected.map((x) => x.id),
        //si no hay letra, sucr y nro se debe enviar null
        nroComprobante:
          this.letraComprobante == "" &&
          this.nroComprobante == "" &&
          this.sucursalCompr == ""
            ? null
            : //se quitan los 0 antepuestos del numero y suc. para que filtre de todas formas.
              `${this.letraComprobante} - ${this.sucursalCompr.replace(
                /^0+/,
                ""
              )} - ${this.nroComprobante.replace(/^0+/, "")}`,
        tipoProveedorId: this.tipoProveedorSelected?.id,
        proveedorId: this.proveedorSelected ? this.proveedorSelected.id : null,
        anulados: this.anuladosSwitch,
        fechaSaldo: this.conSaldoSwitch ? this.fechaHasta : null,
        monedaId: this.monedaSelected?.id,
        fechaCotiza: this.fechaCotiza,
        cotizaFechaComprobante: this.cotizarFechaCompSwitch,
        sinCompFiscal: this.sinCompFiscal,
      };
      try {
        const response = await this.getComprobantesProveedorByFilters(filters);
        this.itemsTotales = response.totales;
        this.itemsDetalles = response.detalleComprobante;
        if (this.itemsDetalles.some((x) => x.haveCotiza === 0))
          this.haveCotiza = false;
        //calculo totales que van debajo de las tablas
        this.itemsDetalles.forEach((e) => {
          this.sumatoriaImportesDetalle += e.importeTotalToEdit;
        });
        this.itemsTotales.forEach((e) => {
          this.sumatoriaImportesTotales += e.importeTotalToEdit;
        });
        this.sumatoriaImportesDetalle = this.formatDecimalMoney(
          this.sumatoriaImportesDetalle
        );
        this.sumatoriaImportesTotales = this.formatDecimalMoney(
          this.sumatoriaImportesTotales
        );
        this.isLoadingTable = false;
      } catch {
        this.isLoadingTable = false;
      }
    },
    formatDecimalMoney(valor) {
      const formatter = new Intl.NumberFormat("es-ES", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(valor);
    },
    exportExcelModelo() {
      let result = [];
      if (this.showDetallesTable && !this.canSeeMultimoneda) {
        this.itemsDetalles?.forEach((x) =>
          result.push({
            ["Proveedor"]: x.proveedorCodNom,
            ["Tipo de comprobante"]: x.tipoCompNombre,
            ["Número"]: x.comprobanteNro,
            ["Fecha comprobante"]: x.fechaComprobante,
            ["Fecha contable"]: x.fechaContable,
            ["Importe"]: x.importeTotalToShow,
          })
        );
      } else if (this.showDetallesTable && this.canSeeMultimoneda) {
        this.itemsDetalles?.forEach((x) =>
          result.push({
            ["Proveedor"]: x.proveedorCodNom,
            ["Tipo de comprobante"]: x.tipoCompNombre,
            ["Número"]: x.comprobanteNro,
            ["Fecha comprobante"]: x.fechaComprobante,
            ["Fecha contable"]: x.fechaContable,
            ["Importe"]: x.importeTotalToShow,
            ["Moneda comprobante"]: x.monedaComprobanteNom.toUpperCase(),
            ["Moneda consulta"]: this.monedaSelected.value.toUpperCase(),
            ["Fecha cotización"]:
              this.fechaCotizaTextField ?? x.fechaComprobante,
          })
        );
      } else {
        this.itemsTotales?.forEach((x) =>
          result.push({
            ["Proveedor"]: x.proveedorCodNom,
            ["Importe"]: x.importeTotalToShow,
          })
        );
      }
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Comprobantes proveedor");
    },
    switchTab(num) {
      switch (num) {
        case 1:
          this.showDetallesTable = true;
          this.showTotalesTable = false;
          break;
        case 2:
          this.showTotalesTable = true;
          this.showDetallesTable = false;
          break;
      }
    },
    selectAllItems() {
      if (this.isSelectedAll) this.tipoComprobanteSelected = [];
      else this.tipoComprobanteSelected = this.tipoComprobantesItems;
    },
    selectByDescription(description) {
      const filteredItems = this.tipoComprobantesItems.filter(
        (item) => item.description === description
      );
      if (filteredItems.length === 0) return; // Si no hay elementos coincidentes, salir

      if (this.isSelectedByDescription(description)) {
        // si ya esta seleccionado, se quitan
        this.tipoComprobanteSelected = this.tipoComprobanteSelected.filter(
          (item) => item.description !== description
        );
      } else {
        // si no estan seleccionados, se agregan concatenando los dos arrays
        this.tipoComprobanteSelected = [
          ...this.tipoComprobanteSelected,
          ...filteredItems,
        ];
      }
    },
    isSelectedByDescription(description) {
      //aca nos fijamos si el array de tipo comprobantes tiene los items que coincidan con los de la description pasada por parametro
      const matchingItems = this.tipoComprobantesItems.filter(
        (item) => item.description === description
      );
      return matchingItems.every((item) =>
        this.tipoComprobanteSelected.some((x) => x.id == item.id)
      );
    },
    setProveedor() {
      if (
        this.provCod == null ||
        this.provCod == undefined ||
        this.provCod == ""
      ) {
        this.provNom = null;
        this.provCod = null;
        this.proveedorSelected = null;
        this.monedaDeProv = null;
        this.updateBanderaFechaCotiza();
      } else this.findProveedorByCodigo();
    },
    findProveedor(proveedor) {
      this.proveedorSelected = proveedor;
      this.provId = this.proveedorSelected.provId;
      this.provNom = proveedor.provNom;
      this.provCod = proveedor.provCod;
      this.proveedorSelected.value1 = this.provNom;
      this.proveedorSelected.value2 = this.provCod;
      this.proveedorSelected.id = this.provId;
      this.setMonedaDelProveedor();
    },
    toggleModalBusquedaProv() {
      this.buscarProveedorModal = !this.buscarProveedorModal;
    },
    async findProveedorByCodigo() {
      try {
        this.proveedoresLoading = true;
        const proveedores = await this.getProveedoresByCod(this.provCod);
        if (proveedores.length === 0) {
          this.setAlert({
            type: "warning",
            message: "Proveedor inexistente",
          });
          this.proveedorSelected = null;
          this.provNom = null;
          this.proveedoresLoading = false;
          return;
        }
        this.proveedoresLoading = false;
        this.proveedorSelected = proveedores[0];
        this.provId = this.proveedorSelected.id;
        this.provNom = this.proveedorSelected.value1;
        this.setMonedaDelProveedor();
      } catch (error) {
        this.proveedoresLoading = false;
        this.proveedorSelected = null;
        this.provId = null;
      }
    },
    goToDeleteComprobante(item) {
      const tipoComp = this.tipoComprobantesItems.find(
        (x) => x.value.trim() == item.tipoCompNombre.trim()
      );
      this.paramsToDelete = {
        movProvId: item.mProvId,
        isCtaCte: true,
        monedaToDetalle: this.monedaSelected,
        fechaCotizaToDetalle: this.cotizarFechaCompSwitch
          ? this.helpers.parseDateToIso(item.fechaComprobante)
          : this.fechaCotiza,
      };
      if (tipoComp.description == "OP") {
        this.paramsToDeleteOp = this.paramsToDelete;
        this.paramsToDeleteOp.isCtaCte = false;
        this.openModalDeleteOp = true;
      } else this.openModalDelete = true;
    },
    async goToVerComprobante(item) {
      this.$store.state.filtersComprobantesProveedor = this.saveCamposFilters;
      //buscamos el tipo de comprobante para ver a que pantalla hay que ir
      const tipoComp = this.tipoComprobantesItems.find(
        (x) => x.value.trim() == item.tipoCompNombre.trim()
      );
      if (tipoComp.description === "OP") {
        this.$router.push({
          name: "VerOrdenDePago",
          params: {
            movProvId: item.mProvId,
            isCtaCte: true,
            monedaToDetalle: this.monedaSelected,
            fechaCotizaToDetalle: this.cotizarFechaCompSwitch
              ? this.helpers.parseDateToIso(item.fechaComprobante)
              : this.fechaCotiza,
            goBackName: "ComprobantesProveedor",
          },
        });
      } else {
        const response = await this.getMonedaDeProveedor(item.provId);
        const monedas = await this.getMonedasTaxonomy("habListado");
        const monedaDef = monedas.find((x) => x.bool == true);
        this.monedaDefectoSistema = monedaDef;
        this.monedaDeProv = response;
        if (this.canSeeMultimoneda) {
          this.$router.push({
            name: "DetalleComprobanteProv",
            params: {
              movProvId: item.mProvId,
              monedaToDetalle: this.monedaSelected,
              fechaCotizaToDetalle: this.cotizarFechaCompSwitch
                ? this.helpers.parseDateToIso(item.fechaComprobante)
                : this.fechaCotiza,
              monedaDefecto: this.monedaDefectoSistema,
              monedaProveedor: this.monedaDeProv,
              goBackName: "ComprobantesProveedor",
            },
          });
        } else {
          this.$router.push({
            name: "DetalleComprobanteProv",
            params: {
              movProvId: item.mProvId,
              monedaToDetalle: this.monedaSelected,
              fechaCotizaToDetalle: null,
              monedaDefecto: this.monedaDefectoSistema,
              monedaProveedor: this.monedaDeProv,
              goBackName: "ComprobantesProveedor",
            },
          });
        }
      }
    },
    customFilterProveedores(item, queryText) {
      return item.value1 || item.value2.indexOf(queryText) > -1;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    resetForm() {
      this.$refs["filters-form"].reset();
      this.nroComprobante = "";
      this.letraComprobante = "";
      this.sucursalCompr = "";
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>

<style scoped>
.center-input >>> input {
  text-align: center;
}
.invalidConfigText {
  color: red;
}
</style>
